.App {
  text-align: center;
}

a {
  all: unset;
  cursor: pointer;
}

h1 {
  font-family: 'Courier New', Courier, monospace;
  margin: 0px;
}

p {
  font-size: 1.25rem;
  margin-bottom: 0px;
}

.story-read {
  width: 900px;
}

.list-item {
  width: 900px;
}

.story-read p {
  text-indent: 2rem;
}

.filled-area {
  background-color: #EBD6B7;
  text-align: left;
  color: #221D00;
  border-radius: 15px;
  -moz-border-radius: 15px;
}

.filled-area-item {
  background-color: #EBD6B7;
  text-align: left;
  color: #221D00;
  border-radius: 30px;
  -moz-border-radius: 30px;
}

.filled-area-item p,h2,h3,h4 {
  margin-bottom: 0px;
}

.main-menu-tibbs-logo {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.tibbs-menu-option:hover {
  background-color: #EBD6B7;
  color: #221D00;
}

.super-thin-container {
  max-width: 720px;
}

.social-media-buttons {
  margin-top: 48px;
}

.social-media-button path {
  fill: #EBD6B7;
}

.filled-area .social-media-button path {
  fill: #221D00;
}

.social-media-button:hover {
  cursor: pointer;
}

.social-media-button:hover path {
  fill: red;
}